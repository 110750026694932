.service-card {
  width: 24rem;
  transition: all 0.4s;
  margin-right: 3.2rem;
  margin-top: 1.2rem;
}

.service-card:hover {
  scale: 1.05;
}

.service-img {
  width: 100%;
  height: 17rem;
}
.service-card-title {
  font-weight: 700;
  color: #c07c1a;
  padding-bottom: 1rem;
  border-bottom: 3px solid #111;
}

.service-custom-btn {
  border: none;
}
