.about-container {
  padding: 2rem 3.2rem;
  background-color: #111;
  color: #fff;
  font-size: 1.2rem;
}

.about-container p {
  margin-bottom: 1.2rem;
}
.first-container p:first-of-type::first-letter {
  font-size: 2rem;
}
.about-content-title {
  color: #d49539;
  font-weight: 700;
}
.about-content-img {
  margin-bottom: 1rem;
}

.tag-line {
  color: green;
  font-weight: 700;
  font-style: italic;
}
