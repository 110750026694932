.services-section {
  padding-top: 2rem;
  background-color: #111;
}

.service-main-heading {
  color: #fff;
  text-align: center;
}
.service-sub-heading {
  color: #d49539;
  font-size: 1.4rem;
  text-align: center;
}
.service-all-button {
  border: none;
}

.about-section {
  padding-top: 2rem;
}
