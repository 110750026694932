.about-card {
  width: 24rem;
  margin-right: 4.8rem;
  margin-top: 1.2rem;
  transition: all 0.4s;
}

.about-card:hover {
  transform: translateY(-1.2rem);
}

.about-img {
  width: 100%;
  height: 17rem;
}
.about-card-title {
  font-weight: 700;
  color: #c07c1a;
  padding-bottom: 1rem;
}

.about-custom-btn {
  border: none;
}
