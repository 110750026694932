.services-card {
  width: 24rem;
  border: none;
  margin-right: 3.2rem;
  margin-top: 1.2rem;
  background-color: transparent;
  color: #fff;
}
.services-card-title {
  font-weight: 700;
  color: #c07c1a;
  font-size: 1.8rem;
}
.services-card-content {
  font-size: 1.2rem;
}
